import { UnionResponseStatus } from "../models/responses";
import ResponseStatus from "../models/enums/ResopnseStatus.enum";

export type GenerateResponseObjReturn<T> = {
  status: UnionResponseStatus;
  data: T;
};

export type GenerateErrorResponseObjReturn = {
  status: ResponseStatus.ERROR;
  data: string;
};

export function generateResponseObj<T>(
  status: UnionResponseStatus,
  data: T
): GenerateResponseObjReturn<T> {
  return { status, data };
}

export function generateErrorResponseObj(
  error: string | Error | null
): GenerateErrorResponseObjReturn {
  let errorMessage;

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  if (typeof error === "string") {
    errorMessage = error;
  }

  return {
    status: ResponseStatus.ERROR,
    data: errorMessage || "unknown error",
  };
}
