import {
  generateErrorResponseObj,
  generateResponseObj,
} from "../utils/generateResponseObj";
import axios from "axios";
import { apiPath } from "../constants/api";
import ResponseStatus from "../models/enums/ResopnseStatus.enum";

export default class restApiService {
  static getLiveRooms = async () => {
    try {
      const { data, status } = await axios.get(`${apiPath.live}`);

      return generateResponseObj(ResponseStatus.SUCCESS, data);
    } catch (error) {
      console.error("Antimat get word list error: ", error);

      return generateErrorResponseObj(
        error instanceof Error ? error.message : null
      );
    }
  };
}
