import { FunctionComponent, useCallback, useEffect } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { isIOS, isDesktop } from "react-device-detect";
import appServices from "../../store/appService";
import restApiService from "../../services/restApiService";
import ResponseStatus from "../../models/enums/ResopnseStatus.enum";
import { ReactComponent as Ico } from "../../assets/ico/logo.svg";

import "./main.scss";

const Main: FunctionComponent = function Main() {
  const { setRooms, rooms } = useLocalObservable(() => appServices);

  useEffect(() => {
    (async () => {
      const response = await restApiService.getLiveRooms();
      if (response.status === ResponseStatus.SUCCESS) {
        if (response.data.length > 1) {
          setRooms(response.data);
          return;
        }
        window.location.href = isIOS
          ? `https://matchtv.ru/football/worldcup`
          : isDesktop
          ? `https://matchtv.ru/football/worldcup`
          : `https://match-club.ru/streaming/${response.data[0].externalRoomId}`;
      }
    })();
  }, []);

  const renderItem = useCallback(
    (item: any, index: any) => (
      <li className="intro__room" key={index}>
        <p className="intro__room-title">{item.name}</p>
        <a
          href={
            isIOS
              ? `https://matchtv.ru/football/worldcup`
              : `https://match-club.ru/streaming/${item.externalRoomId}`
          }
          className="intro__room-button"
        >
          Открыть чат
        </a>
      </li>
    ),
    []
  );

  return (
    <div className="intro">
      <div className="intro__header">
        <a href="https://matchtv.ru/" target="_blank">
          <Ico className="intro__icon-left" />
        </a>
        <span className="intro__icon-right" />
      </div>
      <p className="intro__title">Сейчас в эфире</p>
      <ul className="intro__list">{rooms?.map(renderItem)}</ul>
    </div>
  );
};

export default observer(Main);
