import { action, makeObservable, observable, computed } from "mobx";

class AppService {
  @observable
  public rooms: any = [];

  @action
  public setRooms = (value: any) => {
    this.rooms = value;
  };

  constructor() {
    makeObservable(this);
  }
}

export default new AppService();
